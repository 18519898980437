import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 921.000000 640.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,640.000000) scale(0.100000,-0.100000)">
<path d="M2252 5743 l3 -86 110 -19 c267 -45 462 -142 621 -311 161 -170 239
-364 251 -626 l6 -114 66 7 c36 3 84 6 105 6 l39 0 -6 123 c-11 235 -80 453
-200 632 -53 79 -177 206 -253 261 -173 124 -459 214 -680 214 l-65 0 3 -87z"/>
<path d="M2250 5461 l0 -71 29 0 c44 0 159 -26 232 -53 303 -110 489 -377 489
-704 l0 -46 61 7 c34 3 70 6 80 6 22 0 25 43 9 155 -34 236 -117 404 -269 548
-134 126 -326 204 -543 222 l-88 7 0 -71z"/>
<path d="M2248 5169 l-3 -44 81 -16 c249 -51 414 -236 414 -466 l0 -53 43 0
c68 0 71 6 59 96 -42 308 -223 486 -529 520 l-62 6 -3 -43z"/>
<path d="M723 5000 c-102 -24 -202 -101 -250 -192 l-28 -53 0 -1830 0 -1830
28 -53 c37 -70 95 -125 175 -164 l67 -33 830 0 830 0 57 23 c76 31 160 108
195 180 l28 57 0 1820 0 1820 -28 57 c-35 72 -119 149 -195 180 l-57 23 -810
2 c-445 0 -824 -2 -842 -7z"/>
<path d="M2900 3860 l0 -410 125 0 125 0 0 410 0 410 -125 0 -125 0 0 -410z"/>
<path d="M3340 3861 l0 -411 219 0 c347 0 438 20 494 111 73 119 27 274 -94
310 l-41 13 29 14 c72 37 109 107 101 192 -6 64 -45 116 -114 150 -48 25 -54
25 -321 28 l-273 3 0 -410z m447 214 c30 -34 29 -70 -2 -100 -22 -23 -32 -25
-110 -25 l-85 0 0 81 0 82 87 -6 c79 -5 90 -8 110 -32z m28 -310 c30 -29 33
-73 8 -104 -22 -27 -77 -40 -165 -41 l-68 0 0 85 0 85 100 0 c94 0 103 -2 125
-25z"/>
<path d="M4610 3860 l0 -410 320 0 320 0 0 100 0 100 -195 0 -195 0 0 310 0
310 -125 0 -125 0 0 -410z"/>
<path d="M5597 4263 c-4 -7 -200 -524 -278 -735 l-28 -78 127 0 127 0 20 68
20 67 146 3 146 3 19 -68 20 -68 131 -3 131 -3 -153 408 -153 408 -135 3 c-75
1 -138 -1 -140 -5z m182 -354 c22 -74 41 -138 41 -142 0 -4 -41 -7 -91 -7 -81
0 -90 2 -85 18 20 71 79 259 83 265 7 12 8 12 52 -134z"/>
<path d="M6270 3860 l0 -410 203 0 c276 0 367 21 444 102 130 136 134 457 8
606 -77 91 -159 112 -447 112 l-208 0 0 -410z m359 220 c104 -20 136 -74 135
-230 -1 -159 -36 -205 -166 -216 l-78 -7 0 232 0 231 29 0 c15 0 52 -4 80 -10z"/>
<path d="M7312 4218 c-11 -29 -81 -214 -155 -410 l-135 -358 128 0 127 0 19
68 20 67 146 3 146 3 21 -71 22 -70 129 0 130 0 -25 68 c-14 37 -83 220 -153
407 l-128 340 -136 3 -136 3 -20 -53z m203 -320 c21 -71 36 -130 33 -133 -3
-3 -44 -5 -91 -3 l-85 3 39 130 c53 175 49 163 58 145 4 -8 25 -72 46 -142z"/>
<path d="M8000 3860 l0 -410 115 0 115 0 2 226 3 226 154 -226 154 -226 119 0
118 0 0 410 0 410 -120 0 -120 0 -2 -224 -3 -224 -151 222 -151 221 -117 3
-116 3 0 -411z"/>
<path d="M2880 2790 l0 -410 204 0 c347 0 441 37 519 207 20 43 22 64 22 193
0 118 -4 155 -19 196 -43 116 -123 186 -240 211 -38 8 -141 13 -273 13 l-213
0 0 -410z m419 206 c62 -33 81 -83 81 -215 0 -80 -4 -104 -22 -141 -29 -56
-58 -70 -150 -73 l-73 -2 -3 215 c-1 118 0 221 3 229 4 11 19 12 71 7 37 -4
78 -13 93 -20z"/>
<path d="M3946 3192 c-4 -7 -185 -487 -277 -734 l-28 -78 127 0 128 0 13 53
c24 92 13 87 175 87 l142 0 20 -67 19 -68 131 -3 132 -3 -119 318 c-66 175
-135 360 -155 411 l-36 92 -133 0 c-74 0 -136 -4 -139 -8z m179 -339 c21 -71
40 -136 42 -145 5 -16 -4 -18 -87 -18 -69 0 -91 3 -88 13 3 6 22 72 44 144 21
73 42 133 45 133 3 0 23 -57 44 -127z"/>
<path d="M4480 3100 l0 -99 128 -3 127 -3 3 -307 2 -308 120 0 120 0 2 308 3
307 128 3 127 3 0 99 0 100 -380 0 -380 0 0 -100z"/>
<path d="M5499 3173 c-6 -15 -71 -188 -144 -383 -73 -195 -138 -367 -144 -382
l-11 -28 128 0 128 0 20 68 19 67 146 3 146 3 20 -68 20 -68 131 -3 132 -3
-21 53 c-11 29 -80 213 -153 408 l-133 355 -137 3 -136 3 -11 -28z m226 -454
l7 -29 -92 0 c-73 0 -91 3 -87 14 3 7 22 69 42 137 21 68 40 128 43 134 6 9
72 -185 87 -256z"/>
<path d="M3043 2095 c-116 -31 -186 -90 -237 -195 -30 -63 -31 -69 -31 -205 1
-166 16 -217 91 -303 81 -92 189 -129 357 -120 117 6 179 32 242 99 43 46 67
89 85 150 8 26 7 27 -98 58 -58 17 -106 30 -107 28 -1 -1 -13 -27 -28 -57 -33
-70 -72 -94 -145 -93 -65 0 -98 22 -130 84 -21 40 -23 55 -20 160 3 103 6 119
28 151 32 48 72 70 129 71 70 1 129 -39 153 -105 3 -6 205 36 213 44 8 7 -42
103 -70 136 -63 75 -164 112 -300 111 -44 0 -104 -7 -132 -14z"/>
<path d="M3945 2097 c-168 -44 -262 -155 -285 -339 -8 -67 0 -172 18 -234 24
-78 86 -158 156 -199 84 -49 250 -69 368 -44 203 43 314 215 295 460 -14 172
-92 285 -237 340 -69 27 -239 35 -315 16z m215 -195 c51 -28 82 -90 88 -176
11 -178 -48 -269 -174 -269 -69 0 -115 29 -145 91 -19 39 -23 62 -22 142 1
125 24 177 98 218 32 18 116 14 155 -6z"/>
<path d="M7320 2096 c-108 -24 -190 -85 -242 -183 -38 -71 -51 -146 -46 -261
4 -84 10 -109 35 -164 71 -152 190 -218 392 -218 138 0 241 30 354 102 l47 30
-2 172 -3 171 -192 3 -193 2 0 -85 0 -85 85 0 85 0 0 -40 c0 -36 -4 -41 -39
-60 -137 -70 -274 -31 -309 88 -16 54 -17 192 -1 240 27 83 83 124 169 124 67
0 118 -31 145 -89 0 -1 50 7 110 17 59 11 113 20 118 20 15 0 -2 51 -34 101
-58 92 -155 130 -324 128 -55 0 -125 -6 -155 -13z"/>
<path d="M4640 1690 l0 -410 100 0 100 0 0 308 c0 169 4 300 9 292 4 -8 41
-147 81 -307 l74 -293 92 0 91 0 51 198 c28 108 63 246 79 307 l28 110 3 -308
2 -307 100 0 100 0 0 400 c0 220 0 403 0 408 0 4 -73 7 -162 7 l-162 0 -61
-237 c-33 -130 -63 -239 -66 -243 -5 -4 -65 214 -120 438 l-11 47 -164 0 -164
0 0 -410z"/>
<path d="M6100 1690 l0 -410 120 0 120 0 2 223 3 223 151 -220 150 -221 117
-3 117 -3 0 411 0 410 -115 0 -115 0 -2 -227 -3 -228 -154 228 -155 227 -118
0 -118 0 0 -410z"/>
<path d="M5710 1395 l0 -115 120 0 121 0 -3 113 -3 112 -117 3 -118 3 0 -116z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
